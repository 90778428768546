<template>
  <section id="attendance">
    <div class="container">
      <div class="heading" data-aos="fade-right">
        <h2 class="title font-secondary fs-40 text-teal-dark text-center">Buku Tamu</h2>
      </div>

      <div class="row justify-content-center" data-aos="fade-left">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group required" :class="{ disabled: $root.guest.name }">
                <label for="name">Nama</label>
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  v-model="name"
                  :disabled="$root.guest.name"
                  @keyup="validate"
                >
                <div class="form-help" v-if="errors.name">{{ errors.name }}</div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group required">
                <label for="attendance">Kehadiran</label>
                <select id="attendance" class="form-control" v-model="absence">
                  <option value="going">Hadir</option>
                  <option value="not_going">Tidak Hadir</option>
                  <option value="maybe">Masih Ragu</option>
                </select>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="greeting">Ucapan</label>
                <textarea class="form-control" id="greeting" v-model="message"></textarea>
              </div>
            </div>
            <div class="col-12">
              <transition name="fade">
                <div
                  class="alert alert-success"
                  v-if="successMessage"
                >Thank you! Ucapannya sudah kami terima 😊</div>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-3">
        <button type="button" class="btn btn-primary btn-md" :class="{loading}" @click="submit">
          <i class="loading-icon material-icons">refresh</i>
          <span class="text">Kirim Ucapan</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      name: null,
      absence: "going",
      message: null,
      loading: false,
      errors: {},
      successMessage: false
    };
  },
  methods: {
    validate() {
      this.errors = {};
      let valid = true;

      if (!this.name) {
        console.log(this.name);
        this.errors.name = "Nama harus diisi";
        valid = false;
      }

      return valid;
    },
    submit() {
      let valid = (valid = this.validate());
      const { name, absence, message } = this;
      const data = {
        id: this.$root.guest.id,
        name,
        absence,
        message
      };

      if (!valid) {
        return false;
      }

      this.loading = true;

      this.$root
        .postAttendance(data)
        .then(res => {
          this.successMessage = true;
          setTimeout(() => (this.successMessage = false), 5000);
        })
        .catch(res => {})
        .then(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    rootGuestName() {
      return this.$root.guest.name;
    },
    rootAttendance() {
      return this.$root.guest.attendance;
    },
    rootMessage() {
      return this.$root.guest.message;
    }
  },
  watch: {
    rootGuestName() {
      this.name = this.rootGuestName;
    },
    rootAttendance() {
      this.absence = this.rootAttendance || "Hadir";
    },
    rootMessage() {
      this.message = this.rootMessage;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-help {
  position: absolute;
  padding: 3px 10px;
  color: #fff;
  background: rgb(187, 46, 46);
  font-size: 0.7rem;
  box-shadow: 0 14px 26px -12px rgba(0, 123, 255, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 123, 255, 0.2);
  z-index: 1;
  margin-top: 2px;

  &:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgb(187, 46, 46);
    content: " ";
    position: absolute;
    top: 0;
    transform: translateY(-100%);
  }
}
</style>
