<template>
  <div
    class="agw-hero"
    :class="{ 'with-overlay' : overlay && !!background, 'with-background' : !!background, 'parallax' : parallax, 'has-guest-box': !!$root.guest.name}"
  >
    <div class="agw-hero__images" :class="`transition-${backgroundConfig.transition}`">
      <div class="agw-hero__images__item" v-for="(bg, key) in backgrounds" :key="bg" :class="{ active: key === active }" :style="{ backgroundImage: `url(${bg})` }">
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="agw-hero__content">
            <div class="agw-hero__box left"></div>
            <div class="agw-hero__box right"></div>

            <div class="content mt-5 mb-5 text-white px-4 text-center">
              <h1 class="text-uppercase">
                {{ $parent.title }}
                <template v-if="$parent.mepandes.length">
                  <span>&</span>
                  Mepandes
                </template>
              </h1>

              <div class="name">
                <div class="bridal">
                  <img src="@/assets/icons/decor-1.svg">
                </div>
                <div class="title">
                  {{ $parent.wedding.man.surname }}
                  <span>&</span> 
                  {{ $parent.wedding.woman.surname }}
                </div>
                <div class="bridal bottom">
                  <img src="@/assets/icons/decor-1.svg">
                </div>
              </div>

              <guest-box :floating="false" v-if="$root.scroll.Y < $root.hero.height"/>

              <div class="arrow">
                <a :href="target">
                  <i
                    class="material-icons"
                    v-if="$root.scroll.Y <= $root.hero.height"
                  >arrow_downward</i>
                  <i class="material-icons" v-else>arrow_upward</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuestBox from "./GuestBox";
import { setInterval } from 'timers';

export default {
  components: {
    GuestBox
  },
  props: {
    overlay: {
      type: Boolean,
      default() {
        return false;
      }
    },
    parallax: {
      type: Boolean,
      default() {
        return true;
      }
    },
    background: {
      type: [String, Array]
    },
    backgroundConfig: {
      type: Object,
      default() {
        return {
          transition: 'fade',
          interval: 5000
        }
      }
    },
    target: {
      type: String
    }
  },
  data() {
    return {
      posY: 0,
      active: 0,
    };
  },
  created() {
    this.$root.$on(
      "hero-change",
      () => (this.$root.hero.height = this.$el.offsetHeight)
    );
  },
  computed: {
    backgrounds: function() {
      return Array.isArray(this.background) ? this.background : [this.background] 
    }
  },
  mounted() {
    this.$root.$emit("hero-change");
    window.addEventListener(
      "resize",
      () => (this.$root.hero.height = this.$el.offsetHeight)
    );
    window.addEventListener("scroll", () => {
      window.requestAnimationFrame(() => {
        if (window.scrollY < this.$el.offsetHeight + 200) {
          let scale = (window.scrollY / 10000) * 2 + 1;
          let translateY = window.scrollY / -5 + "px";
          this.$el.style.transform = `scale(${scale}) translateY(${translateY}`;
        } else {
          this.$el.style.transform = `none`;
        }
      });
    });

    $(this.$el).on('click', 'a', function(event) {
       event.preventDefault();

      // Store hash
      var hash = this.hash;

      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 500, function(){

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    });

    setInterval(() => {
      let active = this.active;

      if((active + 1) >= this.backgrounds.length) {
        this.active = 0;
      } else {
        this.active++;
      }
    }, this.backgroundConfig.interval)
  }
};
</script>

<style>
</style>
