<template>
  <div class="">
    <div class="btn-modal-wrapper">
      <div v-if="tooltip" class="tooltip">
        <button class="btn-close absolute right-4 top-2 text-4xl" @click="tooltip = false">
          &times;
        </button>
        <div class="title">Wedding Gift</div>
        <div>Kirim hadiahmu secara digital</div>
      </div>
      <button
        class="button-modal py-2 px-6 bg-gradient from-red-400 to-pink-500 bg-gradient-to-br text-white flex uppercase text-lg mx-auto items-center w-full justify-center"
        @click="open = true"
      >
        <svg
          enable-background="new 0 0 512 512"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          class="w-8 w-100"
        >
          <g>
            <g>
              <path
                d="m301.218 250.733c6.642 6.979 13.876 13.216 20.872 18.581l34.95 9 34.95-9c6.996-5.365 14.23-11.602 20.872-18.581l-55.822-9z"
                fill="#ffacca"
              />
              <path
                d="m412.862 250.733c16.344-17.172 29.086-38.862 25.746-63.287-7.252-53.033-71.449-47.794-81.569-15.489-10.12-32.305-74.317-37.544-81.568 15.489-3.34 24.425 9.402 46.115 25.746 63.287z"
                fill="#ffb8c7"
              />
              <path
                d="m192.631 502.419h303.883c8.553 0 15.487-5.906 15.487-24.487v-203.618c0-2.762-2.238-5-5-5h-139.917l-22.512 9-22.512-9h-139.916c-2.761 0-5 2.238-5 5v203.618c0 18.581 6.933 24.487 15.487 24.487z"
                fill="#fce39f"
              />
              <path
                d="m496.513 493.419h-303.882c-8.553 0-15.487-6.934-15.487-15.487v18.581c0 8.553 6.934 15.487 15.487 15.487h129.43l22.512-9.581 22.511 9.581h129.43c8.553 0 15.487-6.934 15.487-15.487v-18.581c-.001 8.553-6.935 15.487-15.488 15.487z"
                fill="#f5d88b"
              />
              <path
                d="m367.084 269.314h-45.024v224.105l22.512 9 22.512-9z"
                fill="#f3746b"
              />
              <path d="m322.06 493.419h45.023v18.581h-45.023z" fill="#e7635f" />
              <g>
                <path
                  d="m86.573 319.307 22.862-34.181v-48.775c-18.45-5.359-46.994-10.504-61.216 3.121-19.773 18.943 17.221 74.007 17.221 74.007l8.993 10.637z"
                  fill="#f3746b"
                />
                <path
                  d="m104.078 324.134 22.578-32.406v-49.428s-7.09-3.007-17.221-5.95l-22.862 82.955 6.983 8.461z"
                  fill="#e7635f"
                />
                <path
                  d="m74.434 363.356-7.924-8.903-13.182 3.082s-59.993 28.297-52.716 54.7c5.234 18.989 32.375 29.202 50.964 34.058l22.857-40.946v-41.991z"
                  fill="#f3746b"
                />
                <path
                  d="m69.418 450.013 22.545-37.513.002-44.31-7.024-9.222-10.508 4.387-22.857 82.937c10.21 2.668 17.842 3.721 17.842 3.721z"
                  fill="#e7635f"
                />
                <path
                  d="m86.558 319.304.015 24.445-12.141 19.608-21.118-5.826 12.127-44.052z"
                  fill="#c75058"
                />
                <path
                  d="m91.965 368.191 13.998-19.625-1.885-24.432-17.505-4.827-12.139 44.049z"
                  fill="#b74a56"
                />
                <path
                  d="m177.898 203.531-27.591-7.609c-5.58-1.539-11.35 1.739-12.888 7.321l-78.764 285.828c-1.538 5.582 1.738 11.354 7.318 12.893l27.583 7.607 14.572-18.635 70.32-255.16z"
                  fill="#fce39f"
                />
                <path
                  d="m185.322 205.579-7.424-2.047-84.342 306.039 7.432 2.05c5.58 1.539 11.35-1.739 12.888-7.32l78.764-285.828c1.538-5.583-1.738-11.355-7.318-12.894z"
                  fill="#f5d88b"
                />
                <path
                  d="m129.653 378.592 14.839-19.624-2.691-24.458-37.69-10.394-12.148 44.082z"
                  fill="#f3746b"
                />
                <path
                  d="m121.63 349.876h45.725v18.185h-45.725z"
                  fill="#e7635f"
                  transform="matrix(.266 -.964 .964 .266 -239.972 402.828)"
                />
              </g>
              <path
                d="m284.724 15.398c0 27.808 30.661 43.798 38.528 48.086 1.53.834 3.376.834 4.905 0 7.867-4.289 38.528-22.027 38.528-48.086-6.995-21.762-36.109-18.361-40.981-2.808-4.871-15.553-33.985-18.954-40.98 2.808z"
                fill="#ffb8c7"
              />
              <path
                d="m450.984 106.904c1.55.847 3.415.847 4.964 0 7.688-4.203 37.102-18.114 37.102-46.472-6.931-20.763-34.888-17.437-39.584-2.444-4.697-14.993-32.653-18.318-39.584 2.444 0 28.358 29.414 42.269 37.102 46.472z"
                fill="#ffb8c7"
              />
              <path
                d="m213.355 151.194c1.549.847 3.415.847 4.964 0 7.688-4.203 37.102-19.32 37.102-46.472-6.932-20.762-34.888-17.437-39.584-2.444-4.697-14.993-32.653-18.318-39.584 2.444-.001 26.428 29.413 42.269 37.102 46.472z"
                fill="#ffb8c7"
              />
              <g fill="#ffacca">
                <path
                  d="m255.421 104.721c-6.142 19.208-29.414 33.269-37.102 37.472-1.55.847-3.415.847-4.964 0-7.688-4.203-30.96-18.264-37.102-37.472-.543 1.625-.972 3.38-1.235 5.307-3.551 25.966 29.21 45.772 38.44 50.802 1.485.809 3.273.809 4.758 0 9.23-5.031 41.991-24.837 38.44-50.802-.263-1.926-.693-3.681-1.235-5.307z"
                />
                <path
                  d="m366.687 15.398c-6.212 20.097-30.661 34.797-38.528 39.086-1.53.834-3.376.834-4.905 0-7.867-4.289-32.316-18.99-38.528-39.086-.514 1.599-.92 3.321-1.176 5.198-3.677 26.892 30.359 47.388 39.781 52.511 1.486.808 3.268.808 4.753 0 9.422-5.124 43.458-25.619 39.781-52.511-.259-1.877-.665-3.599-1.178-5.198z"
                />
                <path
                  d="m493.051 60.432c-6.142 19.208-29.414 33.269-37.102 37.472-1.549.847-3.415.847-4.964 0-7.688-4.203-30.96-18.264-37.102-37.472-.542 1.625-.972 3.38-1.235 5.307-3.551 25.966 29.21 45.771 38.44 50.802 1.485.809 3.273.809 4.758 0 9.23-5.031 41.991-24.837 38.44-50.802-.264-1.926-.693-3.681-1.235-5.307z"
                />
              </g>
            </g>
          </g>
        </svg>
        <!-- No. Rekening -->
      </button>
    </div>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div v-if="open" class="gift-modal bg-white z-50 fixed inset-0 text-center py-12 max-h-full overflow-auto">
        <button class="btn-close absolute right-4 top-2 text-4xl" @click="open = false">
          &times;
        </button>

        <div class="overflow-y-auto max-h-screen container">
          <svg
            enable-background="new 0 0 512 512"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            class="w-16 mx-auto mb-5"
            style="max-width: 5rem"
          >
            <g>
              <g>
                <path
                  d="m301.218 250.733c6.642 6.979 13.876 13.216 20.872 18.581l34.95 9 34.95-9c6.996-5.365 14.23-11.602 20.872-18.581l-55.822-9z"
                  fill="#ffacca"
                />
                <path
                  d="m412.862 250.733c16.344-17.172 29.086-38.862 25.746-63.287-7.252-53.033-71.449-47.794-81.569-15.489-10.12-32.305-74.317-37.544-81.568 15.489-3.34 24.425 9.402 46.115 25.746 63.287z"
                  fill="#ffb8c7"
                />
                <path
                  d="m192.631 502.419h303.883c8.553 0 15.487-5.906 15.487-24.487v-203.618c0-2.762-2.238-5-5-5h-139.917l-22.512 9-22.512-9h-139.916c-2.761 0-5 2.238-5 5v203.618c0 18.581 6.933 24.487 15.487 24.487z"
                  fill="#fce39f"
                />
                <path
                  d="m496.513 493.419h-303.882c-8.553 0-15.487-6.934-15.487-15.487v18.581c0 8.553 6.934 15.487 15.487 15.487h129.43l22.512-9.581 22.511 9.581h129.43c8.553 0 15.487-6.934 15.487-15.487v-18.581c-.001 8.553-6.935 15.487-15.488 15.487z"
                  fill="#f5d88b"
                />
                <path
                  d="m367.084 269.314h-45.024v224.105l22.512 9 22.512-9z"
                  fill="#f3746b"
                />
                <path
                  d="m322.06 493.419h45.023v18.581h-45.023z"
                  fill="#e7635f"
                />
                <g>
                  <path
                    d="m86.573 319.307 22.862-34.181v-48.775c-18.45-5.359-46.994-10.504-61.216 3.121-19.773 18.943 17.221 74.007 17.221 74.007l8.993 10.637z"
                    fill="#f3746b"
                  />
                  <path
                    d="m104.078 324.134 22.578-32.406v-49.428s-7.09-3.007-17.221-5.95l-22.862 82.955 6.983 8.461z"
                    fill="#e7635f"
                  />
                  <path
                    d="m74.434 363.356-7.924-8.903-13.182 3.082s-59.993 28.297-52.716 54.7c5.234 18.989 32.375 29.202 50.964 34.058l22.857-40.946v-41.991z"
                    fill="#f3746b"
                  />
                  <path
                    d="m69.418 450.013 22.545-37.513.002-44.31-7.024-9.222-10.508 4.387-22.857 82.937c10.21 2.668 17.842 3.721 17.842 3.721z"
                    fill="#e7635f"
                  />
                  <path
                    d="m86.558 319.304.015 24.445-12.141 19.608-21.118-5.826 12.127-44.052z"
                    fill="#c75058"
                  />
                  <path
                    d="m91.965 368.191 13.998-19.625-1.885-24.432-17.505-4.827-12.139 44.049z"
                    fill="#b74a56"
                  />
                  <path
                    d="m177.898 203.531-27.591-7.609c-5.58-1.539-11.35 1.739-12.888 7.321l-78.764 285.828c-1.538 5.582 1.738 11.354 7.318 12.893l27.583 7.607 14.572-18.635 70.32-255.16z"
                    fill="#fce39f"
                  />
                  <path
                    d="m185.322 205.579-7.424-2.047-84.342 306.039 7.432 2.05c5.58 1.539 11.35-1.739 12.888-7.32l78.764-285.828c1.538-5.583-1.738-11.355-7.318-12.894z"
                    fill="#f5d88b"
                  />
                  <path
                    d="m129.653 378.592 14.839-19.624-2.691-24.458-37.69-10.394-12.148 44.082z"
                    fill="#f3746b"
                  />
                  <path
                    d="m121.63 349.876h45.725v18.185h-45.725z"
                    fill="#e7635f"
                    transform="matrix(.266 -.964 .964 .266 -239.972 402.828)"
                  />
                </g>
                <path
                  d="m284.724 15.398c0 27.808 30.661 43.798 38.528 48.086 1.53.834 3.376.834 4.905 0 7.867-4.289 38.528-22.027 38.528-48.086-6.995-21.762-36.109-18.361-40.981-2.808-4.871-15.553-33.985-18.954-40.98 2.808z"
                  fill="#ffb8c7"
                />
                <path
                  d="m450.984 106.904c1.55.847 3.415.847 4.964 0 7.688-4.203 37.102-18.114 37.102-46.472-6.931-20.763-34.888-17.437-39.584-2.444-4.697-14.993-32.653-18.318-39.584 2.444 0 28.358 29.414 42.269 37.102 46.472z"
                  fill="#ffb8c7"
                />
                <path
                  d="m213.355 151.194c1.549.847 3.415.847 4.964 0 7.688-4.203 37.102-19.32 37.102-46.472-6.932-20.762-34.888-17.437-39.584-2.444-4.697-14.993-32.653-18.318-39.584 2.444-.001 26.428 29.413 42.269 37.102 46.472z"
                  fill="#ffb8c7"
                />
                <g fill="#ffacca">
                  <path
                    d="m255.421 104.721c-6.142 19.208-29.414 33.269-37.102 37.472-1.55.847-3.415.847-4.964 0-7.688-4.203-30.96-18.264-37.102-37.472-.543 1.625-.972 3.38-1.235 5.307-3.551 25.966 29.21 45.772 38.44 50.802 1.485.809 3.273.809 4.758 0 9.23-5.031 41.991-24.837 38.44-50.802-.263-1.926-.693-3.681-1.235-5.307z"
                  />
                  <path
                    d="m366.687 15.398c-6.212 20.097-30.661 34.797-38.528 39.086-1.53.834-3.376.834-4.905 0-7.867-4.289-32.316-18.99-38.528-39.086-.514 1.599-.92 3.321-1.176 5.198-3.677 26.892 30.359 47.388 39.781 52.511 1.486.808 3.268.808 4.753 0 9.422-5.124 43.458-25.619 39.781-52.511-.259-1.877-.665-3.599-1.178-5.198z"
                  />
                  <path
                    d="m493.051 60.432c-6.142 19.208-29.414 33.269-37.102 37.472-1.549.847-3.415.847-4.964 0-7.688-4.203-30.96-18.264-37.102-37.472-.542 1.625-.972 3.38-1.235 5.307-3.551 25.966 29.21 45.771 38.44 50.802 1.485.809 3.273.809 4.758 0 9.23-5.031 41.991-24.837 38.44-50.802-.264-1.926-.693-3.681-1.235-5.307z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="container">
          <div class="text-2xl py-6 mb-5">
            Doa, berkah, cinta & dukungan Anda terhadap setiap jalan yang kami pijak adalah hadiah terbesar yang pernah kami minta. Namun, jika Anda ingin mengirim sesuatu, Anda dapat mengirimkannya melalui rekening dibawah ini.
          </div>
          
          <div>
            <div class="mb-3">
              <img src="https://www.atmbersama.com/data/upload/images/e799104b9fe06b73e3870a7ae1f9fbfe.png" width="150"  />
            </div>
            <div style="font-size: 1.2rem">
              <strong>008801064359508</strong>
            </div>
            <div>a.n Luh Putu Mertasari</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      open: false,
      tooltip: true,
    }
  },
})
</script>

<style lang="scss" scoped>
.btn-modal-wrapper {
  z-index: 5;
  position: fixed;
  bottom: .5rem;
  left: 75px;

  .tooltip {
    opacity: 1;
    width: 200px;
    background: #fff;
    padding: .5rem 0.75rem;
    transform: translateY(-120%);
    box-shadow: 0 5px 15px -5px rgba(0,0,0, 0.25);

    &:after {
      content: '';
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      position: absolute;
      bottom: 0;
      transform: translateY(100%) translateX(50%);
      filter: drop-shadow(0 5px 15px rgba(0,0,0, 0.25))
    }

    .title {
      font-size: 1.1rem;
      font-weight: bolder;
      margin-bottom: .25rem;
    }

    .btn-close {
      font-size: 1.1rem;
      top: .25rem;
      right: .25rem;
    }
  }
}

.button-modal {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 50rem;
  background: rgb(0, 162, 255);
  background-image: linear-gradient(90.2deg, #83aff5 2%, #61d4ca 98.3%);

  svg {
    max-width: 70%;
    margin-left: -.5rem;
  }
}

.gift-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  padding-top: 5rem;
}

.btn-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: 0;
  background: transparent;
  font-size: 2rem;
}
</style>
