<template>
    <section class="mepandes" v-if="mepandes.length">
        <div class="heading text-center with-border mar-bottom-40" data-aos="fade-up">
            <h2 class="title text-uppercase text-teal-dark">Mepandes</h2>
            <div class="sub-title">Potong Gigi</div>
        </div>

        <ul class="text-center persons">
            <li class="person" data-aos="fade-up" v-for="person in mepandes" :key="person">
                <img src="@/assets/icons/arrow.svg" />
                <span>{{ person }}</span>
                <img src="@/assets/icons/arrow.svg" class="flip-horinzontal" />
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: ['mepandes']
}
</script>
