<template>
    <div class="guest-box" v-if="$root.guest.name" :class=" { collapsed : $root.guest.collapsed, floating }">
      <!-- <div class="box-decoration">
        <img src="@/assets/images/corners/16.svg" />
        <img src="@/assets/images/corners/16.svg" />
        <img src="@/assets/images/corners/16.svg" />
        <img src="@/assets/images/corners/16.svg" />
      </div> -->
      <div class="guest-box__title">Kepada {{ $root.guest.title }}.</div>
      <div class="guest-box__name">{{ $root.guest.name }}</div>
      <button class="guest-box__collapse" @click="$root.guestToggle">
        <i class="material-icons" v-if="!$root.guest.collapsed">close</i>
        <i class="material-icons" v-else>keyboard_arrow_up</i>
      </button>
    </div>
</template>

<script>
// import axios from 'axios';

export default {
    props: ['floating'],
    updated() {
      this.$root.$emit('hero-change');
    }
}
</script>
<style lang="scss" scoped>
// .guest-box.floating {
//     border: 0;
//     box-shadow: none;
//     padding: 10px 30px;
//     margin-right: 5px
// }
// .box-decoration {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     left: 0;
//     top: 0;
// }

// .box-decoration img {
//     width: 19%;
//     position: absolute;
// }

// .box-decoration img:nth-child(1) {
//     left:0;
//     transform: translate(-12%, -12%);
// }

// .box-decoration img:nth-child(2) {
//     right:0;
//     transform: translate(12%, -12%) scaleX(-1);
// }


// .box-decoration img:nth-child(3) {
//     left:0;
//     bottom: 0;
//     transform: translate(-12%, 12%) scaleY(-1);
// }

// .box-decoration img:nth-child(4) {
//     right:0;
//     bottom: 0;
//     transform: translate(12%, 12%) scaleX(-1) scaleY(-1);
// }
</style>
